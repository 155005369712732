@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccount {
    --my-account-content-border: 1px solid #{$grey5};

    &-Wrapper {
        display: block;
        @include desktop {
            display: grid;
            grid-column-gap: 48px;
        }
    }

    &-TabContent {
        @include mobile {
            margin-left: var(--content-padding);
            margin-right: var(--content-padding);
        }
        @include desktop {
            padding: 0;
            border: 0;
        }
    }

    &-Heading {
        font-weight: 700;
        font-size: 30px;
        line-height: 140%;
        @include mobile {
            display: block;
        }
    }
}
