@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccount {
    &-TabContent {
        &_tab_create-shopping-list {
            @include from-tablet {
                padding-top: 50px;
            }
        }
    }
}

.MyAccountCreateShoppingList {
    .Form {
        @include desktop {
            max-width: 536px;
        }
    }

    .FieldForm {
        &-Fields {
            margin-bottom: 30px;
        }
    }

    .Field {
        &-Wrapper {
            display: grid;
            align-items: center;
            @include desktop {
                grid-template-columns: 25% auto;
            }

            @include tablet {
                grid-template-columns: 120px auto;
            }
        }

        &-AdditionalLabel {
            font-size: 13px;
            margin-top: 24px;
            text-align: right;

            @include desktop {
                padding-right: 25px;
            }

            @include tablet {
                padding-right: 30px;
            }

            @include mobile {
                text-align: left;
            }
        }
    }

    &-BackButton {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 13px;
        font-weight: 700;

        &,
        &:not([disabled]):hover {
            color: $black;
            border-bottom: 1px solid $grey5;
            text-transform: uppercase;
            padding: 6px 0;
            text-decoration: none;
        }

        svg {
            width: 12px;
            height: 12px;
            margin-right: 10px;

            path {
                fill: $black;
            }
        }
    }

    .Button {
        display: block;
        margin-left: auto;

        @include tablet {
            width: calc(100% - 120px);
        }
    }
}
