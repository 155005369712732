@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccountCustomerTable {
    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 48px;
    }
    > div:first-child {
        margin-bottom: 65px;
    }
    .Button {
        --button-height: 40px;
        --button-hover-height: 40px;
        --button-border-radius: 0;
        --button-hover-border-radius: 0;
        --button-padding: 40px;
        --button-hover-padding: 40px;
        letter-spacing: unset;
        font-weight: bold;
        font-size: 13px;

        @include desktop {
            --button-padding: 20px;
            --button-hover-padding: 20px;
        }

        @include wide-desktop {
            --button-padding: 40px;
            --button-hover-padding: 40px;
        }
    }
    tbody th,
    tbody td {
        letter-spacing: 0;
        font-size: 13px;
        font-weight: normal;
    }
    tbody th {
        color: $grey3;
    }

    &-ChangeNewsletterLink {
        color: $grey-dark;
        font-size: 13px;
        margin-left: 7px;
        font-weight: normal;
    }
}
