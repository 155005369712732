@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --filter-button-backgroud: #{$black};
    --filter-button-hover-backgroud: #{$grey80};
    --filter-button-border: #{$black};
    --filter-button-hover-border: #{$grey80};
}

.MyAccountFilter {
    margin-block-end: 32px;

    @include mobileAndTablet {
        background: $grey20;
    }

    @include desktop {
        display: grid;
        grid-template-columns: 1fr minmax(auto, 234px);
        margin-block-end: 40px;
        border: 1px solid $grey40;
    }

    & > :first-of-type,
    & > :last-of-type {
        padding-inline: 24px;
        padding-block: 27px 24px;

        @include desktop {
            padding-inline: 30px 24px;
            padding-block: 28px 32px;
        }
    }

    & > :first-of-type {
        @include mobileAndTablet {
            padding-block-end: 20px;
        }

        @include mobile {
            padding-block-end: 12px;
        }
    }

    & > :last-of-type {
        @include mobileAndTablet {
            padding-block-start: 20px;
        }

        @include mobile {
            padding-block-start: 12px;
        }

        @include desktop {
            background: $grey20;
            border-left: 1px solid $grey40;
        }
    }

    &-Toggle {
        grid-column: 1/-1;

        & > button {
            font-family: var(--font-switzer);
            font-weight: var(--secondary-font-weight-bold);
            font-size: 13px;
            line-height: 17px;
            color: $blue50;
            cursor: pointer;

            .ChevronIcon {
                margin-inline-start: 6px;
                path {
                    fill: $blue50;
                }
            }

            &:after {
                content: '';
                position: absolute;
                inset-inline: 0;
                inset-block-end: 0;
                transform: translateY(3px);
                height: 1px;
                background: $grey30;
            }

            &:hover,
            &:focus {
                &:after {
                    background: $blue50;
                }
            }
        }
    }

    &-Content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        row-gap: 24px;
        column-gap: 48px;

        @include desktop {
            column-gap: 72px;
            row-gap: 30px;
        }

        .Field {
            margin: 0;

            input,
            select {
                width: 100%;
            }
        }

        fieldset {
            & > legend {
                font-family: var(--font-switzer);
                font-weight: var(--secondary-font-weight-normal);
                font-size: 13px;
                line-height: 17px;
            }
        }
    }

    &-Action {
        --button-background: var(--filter-button-backgroud);
        --button-hover-background: var(--filter-button-hover-backgroud);
        --button-border: var(--filter-button-border);
        --button-hover-border: var(--filter-button-hover-border);

        display: flex;
        align-items: center;

        & > .Button {
            width: 100%;
        }
    }
}
