@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccountDashboard {
    display: block;

    button + button {
        @include from-tablet {
            margin-left: 12px;
        }
    }
    &-DetailsLink {
        min-width: 157px;
    }
    &-Addresses h3 {
        font-size: 22px;
        margin: 65px 0 15px 0;
        line-height: 140%;
    }
    &-AddressesTables {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 48px;
        }
    }

    .MyAccountAddressTable {
        &:last-of-type {
            @include mobile {
                margin-bottom: 5px;
            }
        }
        @include desktop {
            margin-bottom: 15px;
        }
    }

    &-AccountManagers {
        > div {
            padding: 7px 23px 0;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 65px;
            @include mobile {
                padding: 7px 10px 0;
            }
        }
        .MyAccountManagerItem {
            &:first-of-type {
                @include mobile {
                    margin-bottom: 15px;
                }
            }
            @include desktop {
                min-width: 33%;
            }
        }
    }
}
