@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccountShoppingLists {
    @include desktop {
        margin-bottom: 150px;
    }

    @include mobileAndTablet {
        margin-bottom: 100px;
    }

    &-Table {
        @include desktop {
            width: 100%;
        }

        @include mobileAndTablet {
            min-width: 100%;
        }

        tr {
            th,
            td {
                &:last-child {
                    width: 80px;
                    min-width: 80px;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        .Button {
            word-break: break-word;
        }

        &Cell {
            &_Name {
                color: $default-primary-base-color;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .Icon {
        &-Remove {
            width: 13px;
            height: 15px;
        }
    }

    &-Button {
        position: absolute;
        top: -72px;
        right: 0;

        svg {
            margin-right: 14px;

            path {
                fill: $white;
            }
        }
    }

    &-Pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;

        @include mobile {
            flex-direction: column-reverse;
            align-items: center;
        }

        ul {
            margin: 0;
        }

        .CategoryPagination {
            @include mobile {
                display: flex;
            }
        }
    }

    &-PageSize {
        display: flex;
        align-items: center;

        @include mobile {
            margin-top: 30px;
        }

        label {
            font-size: 13px;
        }

        .Field {
            margin-top: 0;
            margin-left: 10px;

            &Select {
                min-width: unset !important;
            }
        }
    }
}
