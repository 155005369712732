@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccountAddressTable {
    margin-bottom: 60px;
    .Button {
        &_isQuartiary {
            font-size: 13px;
            line-height: 17px;
            letter-spacing: unset;
            --button-border: #{$black};
            --button-background: #{$black};
            --button-color: #{$white};
        }
    }
    tbody th,
    tbody td {
        letter-spacing: 0;
        font-size: 13px;
        font-weight: normal;
    }
    tbody th {
        color: $grey3;
    }
}
