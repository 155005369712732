@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccountMyOrders {
    &-Table {
        thead th {
            color: $black;
            letter-spacing: 0;
            font-size: 13px;
        }
        th {
            border-radius: 0;

            &:first-of-type {
                border-radius: 0;
            }

            &:nth-of-type(3) {
                @include mobile {
                    border-radius: 0;
                }
            }
            &:last-of-type {
                border-radius: 0;
            }
        }

        td {
            letter-spacing: 0;
            font-size: 13px;
        }
        td:first-of-type {
            letter-spacing: unset;
            font-weight: 500;
        }
    }

    td:first-of-type,
    th:first-of-type {
        width: 135px;
    }

    td:last-of-type,
    th:last-of-type {
        width: 114px;
        text-align: center;
    }

    th {
        word-break: break-word;
        line-height: 100%;
        vertical-align: middle;
    }
    table {
        overflow-x: auto;
        display: block;
        width: 100%;
        max-width: calc(100vw - (var(--my-account-wrapper-margin-mobile) * 2));

        @include tablet {
            max-width: calc(100vw - 28px);
        }
        @include desktop {
            width: 700px;
        }
        @include wide-desktop {
            width: 100%;
            display: table;
        }

        &::-webkit-scrollbar {
            height: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $default-primary-base-color;
            border-top: 15px solid $white;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey5;
            border-top: 15px solid $white;
        }
    }

    &-ListError {
        color: $red;
        margin-bottom: 22px;
    }

    &-FilterRange {
        display: flex;
        align-items: center;

        & > span {
            margin-inline: 10px;
        }
    }

    &-FiltersWrapper {
        margin-bottom: 40px;

        .Field_type_number {
            @include qty-input;
            margin: 0 8px 0 0;

            input[type='number'] {
                margin: 0;
            }
            button {
                display: none;
            }
        }
    }
    &-Filters {
        width: 100%;
    }
    &-FiltersForm {
        border: 0;
        background: $grey20;
        padding: 0 25px;
        @include tablet {
            padding: 25px;
        }
        @include desktop {
            display: flex;
            padding: 25px;
            background: $white;
            border: 1px solid $grey40;
        }
        .MyAccountOrders-FormField {
            &Title {
                font-size: 13px;
            }

            .Field {
                margin-top: 10px;
            }
        }
        .ExpandableContent {
            &-Heading {
                font-weight: 700;
                cursor: pointer;

                font-size: 13px;
                line-height: 17px;
                color: $default-primary-base-color;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                svg {
                    width: 8px;
                    margin-left: 8px;
                }
            }
            &:last-of-type {
                @include mobile {
                    border: 0;
                }
            }
            &-Button {
                width: 100%;
                cursor: pointer;
                > svg {
                    display: none;
                }
            }
            &-Content {
                opacity: 0;
                max-height: 0;

                @include desktop {
                    position: static;
                    margin-top: 0;
                }
            }
        }
        &_isOpen .ExpandableContent-Content {
            opacity: 1;
            max-height: 100%;
            @include desktop {
                margin-top: 12px;
            }
        }
    }

    &-FiltersActions {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--filters-actions-background-color);
        padding: 25px 0;

        @include desktop {
            position: absolute;
            right: -25px;
            top: -25px;
            bottom: -25px;
            width: 30%;
            max-width: 234px;
            background: $grey20;
            padding: 25px;
            border-left: 1px solid $grey40;
        }
        button {
            width: 100%;
            background: $black;
            border-color: $black;
        }
    }

    &-Pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;

        .CategoryPagination {
            margin-top: 0;
        }
    }
}

[dir='ltr'] .MyAccountMyOrders {
    &-PageSize {
        display: flex;
        align-items: center;

        label {
            margin-right: 16px;
        }
        .Field.Field_type_select {
            margin-top: 0;
        }
        .Field_type_select .FieldSelect-Option:first-of-type,
        .Field_type_select .FieldSelect-Option {
            padding: 7px 17px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
        }
        .FieldSelect {
            min-width: 75px;
        }
        .Field_type_select .FieldSelect-Select {
            padding: 8px 30px 8px 17px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            /* identical to box height, or 185% */

            color: #797979;
            + .ChevronIcon {
                right: 15px;
                path {
                    stroke: $black;
                }
            }
        }
        .FieldSelect-Select,
        input {
            border: 1px solid #d1d1d1;
        }
    }
}
