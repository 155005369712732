@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccountTabList {
    --my-account-tab-list-border-radius: 0;
    --my-account-tab-list-item-active-color: #{$default-primary-base-color};
    margin-bottom: 25px;

    &-ExpandableContentButton {
        > svg path {
            @include mobileAndTablet {
                fill: $black;
            }
        }
        @include mobileAndTablet {
            border: 1px solid $grey4;
            padding: 10px var(--content-padding);
            margin-left: var(--content-padding);
            margin-right: var(--content-padding);
            width: auto;
        }
        @include tablet {
            margin-left: 0;
            margin-right: 0;
        }
    }
    &-ExpandableContentHeading {
        @include mobileAndTablet {
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: $default-primary-base-color;
        }

        > svg {
            @include mobileAndTablet {
                max-width: 16px;
                margin-right: 12px;
            }
        }

        > svg,
        > svg path {
            @include mobileAndTablet {
                fill: var(--my-account-tab-list-item-active-color);
            }
        }
    }
    &-ExpandableContentContent_isContentExpanded {
        @include mobileAndTablet {
            border: 1px solid $grey4;
            margin-left: var(--content-padding);
            margin-right: var(--content-padding);
            padding-top: 20px;
            border-top: 0;
        }
        @include tablet {
            margin-left: 0;
            margin-right: 0;
        }
        .MyAccountTabListItem-Button {
            @include mobileAndTablet {
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
}
