@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.MyAccountManagerItem {
    &-Name,
    &-Phone,
    &-Email {
        font-size: 13px;
        line-height: 140%;
        margin: 0;
    }

    &-Phone,
    &-Email {
        display: inline-block;
        width: 100%;
        font-weight: normal;
    }
    &-Name {
        font-size: 13px;
        line-height: 140%;
        font-weight: bold;
    }
    &-Phone {
        color: $black;
        margin: 3px 0;
    }
}
