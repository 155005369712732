@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ContractorAccountForm {
    .Field_type_textarea > textarea {
        width: 100%;
    }
    .Field-CheckboxLabel {
        font-size: 13px;
        line-height: 16px;
        color: $grey3;
    }
    .Field-CheckboxLabel[for*='consent'] {
        align-items: flex-start;
        span {
            display: block;
            a,
            p {
                font-size: 13px;
                line-height: 16px;
            }
            a {
                font-weight: normal;
            }
            p {
                color: $grey3;
            }
            p + p {
                margin-top: 10px;
                font-size: 11px;
                line-height: 15px;
            }
        }
        .input-control {
            min-width: 21px;
            @include desktop {
                min-width: var(--checkbox-width);
            }
        }
    }
    .MyAccountOverlay-Consent {
        flex-direction: column;
        align-items: flex-start;
    }

    .Field.Field_type_checkbox {
        @include desktop {
            margin-left: 25px;
        }
    }
}
