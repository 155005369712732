@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

[dir='ltr'] .MyAccountTabListItem {
    --my-account-tab-list-item-color: #{$black};
    --my-account-tab-list-item-active-color: #{$default-primary-base-color};
    align-items: center;
    display: flex;
    @include desktop {
        padding-left: 24px;
    }
    > svg {
        max-width: 16px;
    }
    > svg,
    > svg path {
        fill: $black;
    }
    &-Button {
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        padding: 10px 16px;

        @include mobileAndTablet {
            min-width: unset;
        }
        .ChevronIcon {
            display: none;
        }
    }

    &_isActive,
    &:hover {
        > svg,
        > svg path {
            fill: var(--my-account-tab-list-item-active-color);
        }
        .MyAccountTabListItem-Button {
            font-weight: normal;
        }
    }
}
