@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.MyAccountMyOrders {
    &-Table {
        th {
            border-radius: 0;

            &:first-of-type {
                border-radius: var(--table-head-radius) 0 0 var(--table-head-radius);
            }

            &:nth-of-type(3) {
                @include mobile {
                    border-radius: 0 var(--table-head-radius) var(--table-head-radius) 0;
                }
            }
            &:last-of-type {
                border-radius: 0 var(--table-head-radius) var(--table-head-radius) 0;
            }

            &.center {
                text-align: center;
            }
        }

        td:first-of-type {
            color: $default-primary-base-color;
            letter-spacing: 0.05em;
            font-weight: 500;
        }
    }
}
